/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FC } from "react"
import { OG_LOCALES, ROOT_URL } from "../../constants"

interface Props {
  description?: string
  title: string
  children?: ReactNode
  pathname: string
}

const Seo: FC<Props> = ({ description = "", title, children, pathname }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ogLocale = pathname?.includes("ja/") ? OG_LOCALES.ja : OG_LOCALES.en
  const ogImage = pathname?.includes("ja/") ? "ja" : "en"

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:creator"
        content={site.siteMetadata?.social?.twitter || ``}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="og:url" content={ROOT_URL} />
      <meta name="og:locale" content={ogLocale} />
      <meta name="og:image" content={`${ROOT_URL}OGP_${ogImage}.jpg`} />
      <meta name="og:image:alt" content="The Fireworks Map App Logo" />
      {children}
    </>
  )
}

export default Seo
