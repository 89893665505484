import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  border-top: thin solid #404040;
  align-items: center;

  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const LinksContainer = styled.div`
  display: flex;
  align-items: center;

  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const LinkButton = styled(Link)`
  padding: 16px 10px;
  color: #888;
  font-size: 14px;

  margin: 0 10px;
  @media (min-width: 768px) {
    margin: 10px;
  }
`

const Copyright = styled.p`
  margin: 20px;
  font-size: 12px;
`

const Footer = () => {
  return (
    <Container>
      <LinksContainer>
        <LinkButton to="/privacy_policy">Privacy Policy</LinkButton>
        <LinkButton to="/terms_of_services">Terms of Services</LinkButton>
      </LinksContainer>
      <Copyright>© 2019 - {new Date().getFullYear()} nabehide</Copyright>
    </Container>
  )
}

export default Footer
