import { Link } from "gatsby"
import React, { ElementType } from "react"
import { FC } from "react"
import styled from "styled-components"

const Container = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.a`
  display: flex;
  align-items: center;

  padding: 10px;
  margin: 20px;
`

const Icon = styled.img`
  border-radius: 10px;
  margin-right: 10px;
`

const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
`

const LangContainer = styled.div`
  margin: 20px;
`

const UnselectedLink = styled(Link)`
  text-decoration: underline;
`

interface Props {
  isAbsolute: boolean
  isLangShown: boolean
  location: any
}

const Header: FC<Props> = ({
  isAbsolute = false,
  isLangShown = false,
  location,
}) => {
  const { pathname } = location
  return (
    <Container style={isAbsolute ? { position: "absolute" } : {}}>
      <TitleContainer href="/">
        <Icon src="/logo.png" alt="icon" width={40} height={40} />
        <Title>Firework</Title>
      </TitleContainer>
      {isLangShown && (
        <LangContainer>
          <LangLink to="/" pathname={pathname}>
            English
          </LangLink>{" "}
          /{" "}
          <LangLink to="/ja/" pathname={pathname}>
            日本語
          </LangLink>
        </LangContainer>
      )}
    </Container>
  )
}

const LangLink = ({ children, to, pathname, ...rest }) => {
  const Tag = pathname === to ? (Link as ElementType) : UnselectedLink

  return (
    <Tag to={to} {...rest}>
      {children}
    </Tag>
  )
}

export default Header
