import * as React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = ({
  isAbsolute = false,
  location,
  children,
  isLangShown = false,
}) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div data-is-root-path={isRootPath}>
      <Header
        isAbsolute={isAbsolute}
        isLangShown={isLangShown}
        location={location}
      />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
