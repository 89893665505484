export const ROOT_URL = "https://fireworks.nabehide.com/"

export const LANGS = {
  en: "en",
  ja: "ja",
}

export type Lang = typeof LANGS[keyof typeof LANGS]

export const OG_LOCALES = {
  en: "en_US",
  ja: "ja_JP",
}

export type OgLocale = typeof OG_LOCALES[keyof typeof OG_LOCALES]
